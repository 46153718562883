import logo from './logo.svg';
import Boards from './Components/Pages/Boards/Boards';
import './App.css';

function App() {
  return (
    <div className="App">
      <Boards />
    </div>
  );
}

export default App;
